export enum StorageKey {
    isLogged = "isLogged",
    lang = "lang",
    tokenGoogleID = "tokenGoogleID",

    restaurantCategories = "restaurantCategories",
    dishCategories = "dishCategories",
    chefCategories = "chefCategories",
    experienceCategories = "experienceCategories",

    restaurantTags = "restaurantTags",
    dishTags = "dishTags",
    chefTags = "chefTags",
    experienceTags = "experienceTags",
}